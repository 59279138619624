// Project: GalaxyComplete
// Created: 10/19/20 by sammy
// File: GmAutoAllocation

import * as React from "react";
import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    Grid,
    Link,
    ListSubheader,
    Step,
    StepLabel,
    Stepper,
    SvgIcon,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { useAppServices } from "../../app/services";
import { GmAutoAllocationStep, GmMigrationAutoAllocationState } from "../GmMigrationService";
import { GiBigGear } from "react-icons/gi";
import { GoTools } from "react-icons/go";

import { useNavigateToMigrationWizardScreen } from "../CmcMigrationCommon";
import { AllocationProgressView, GalaxyMigrateAutoAllocationProgressDialog } from "./GmAutoAllocationProgress";
import { GalaxyMigrateDeploymentDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { isDeploymentGteVersion } from "../../deployment/DeploymentCommon";
import { useGmAutoAllocationState } from "./GmAutoAllocationCommon";
import { useNavigateToAutoAllocation } from "../wizard/GmMigrationWizardUtils";
import { PureCBSAllocateVolumesStep } from "./Integrations/PureStorageIntegration";
import { AzureAllocateVolumesStep } from "./Integrations/azure/AzureIntegration";
import { DGSAllocateVolumesStep } from "./Integrations/DGSIntegration";
import { AWSAllocateVolumesStep } from "./Integrations/AWSIntegration";
import { IntegrationConfigInfo, IntegrationDefinition, IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { getModuleConfigByModule, IntegrationAlertConfig, IntegrationCard, useRedirectToIntegrations } from "../../integrations/IntegrationsCommon";
import { NetappAllocateVolumesStep } from "./Integrations/NetappIntegration";
import { BackButton } from "../../../common/CommonButtons";
import { v4 as uuid } from "uuid";
import { PowerStoreAllocateVolumesStep } from "./Integrations/PowerStoreIntegration";
import { PowerMaxIntegration } from "./Integrations/PowerMaxIntegration";
import { GcpAllocateVolumesStep } from "./Integrations/GcpIntegration";
import { OracleAllocateVolumesStep } from "./Integrations/OracleIntegration";
import { SilkSdpAllocateVolumesStep } from "./Integrations/SilkSdpIntegration";
import { DigitalOceanAllocateVolumesStep } from "./Integrations/DigitalOceanIntegration";
import { PowerFlexAllocateVolumesStep } from "./Integrations/PowerFlexIntegration";
import { AzureElasticSanAllocateVolumesStep } from "./Integrations/AzureElasticSanIntegration";
import { useMountEffect } from "../../../common/hooks/hookslib";
import { VmwareDatastoreAllocateVolumesStep } from "./Integrations/VmwareDatastoreIntegration";
import { useListIntegrationModules, useListProjectIntegrations, useTestProjectIntegration } from "../../integrations/integration_hooks";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { useIntegrationRedirectState } from "../../integrations/IntegrationRedirectState";
import { NutanixAllocateVolumesStep } from "./Integrations/NutanixIntegration";
import { OvirtAllocateVolumesStep } from "./Integrations/OvirtIntegration";

// ======================
// GmAutoAllocationScreen
// ======================

export const GM_AUTO_ALLOC_MIN_VERSION = "1.0.9";

export const isGmAutoAllocSupported = (deployment: GalaxyMigrateDeploymentDetails) => {
    return isDeploymentGteVersion(deployment, GM_AUTO_ALLOC_MIN_VERSION);
};

interface GmAutoAllocationScreenProps {}

export const GmAutoAllocationScreen: React.FC<GmAutoAllocationScreenProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    const { gmMigrationService } = useAppServices();

    const navigateBackToWizard = useNavigateToMigrationWizardScreen(true);

    const exitWizard = () => {
        gmMigrationService.initAutoAllocationWizard();
        navigateBackToWizard();
    };

    useMountEffect(() => {
        if (!state.deploymentDetails) {
            navigateBackToWizard();
        }
    });

    return (
        <ScreenContainer>
            <BackButton navFunction={exitWizard} label={"Back To Wizard"} />
            <ScreenTitleBar title={`Allocate Migration Session Volumes`} />
            <Typography paragraph>{`Automatically create and provision volumes from destination storage`}</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={2}>
                    <AutoAllocateStepper />
                </Grid>
                <Grid item xs={12} lg={10}>
                    {state.currentStep === GmAutoAllocationStep.SELECT_INTEGRATION && <IntegrationSelectionStep />}
                    {state.currentStep === GmAutoAllocationStep.PREPARE_HOST && <PrepareHostStep />}
                    {state.currentStep === GmAutoAllocationStep.ALLOCATE_VOLUMES && <AllocateVolumesStep />}
                </Grid>
            </Grid>
            <GalaxyMigrateAutoAllocationProgressDialog />
        </ScreenContainer>
    );
});

// ======================
// AutoAllocateStepper
// ======================

interface AutoAllocateStepperProps {}

const AutoAllocateStepper: React.FC<AutoAllocateStepperProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    return (
        <Card>
            <ListSubheader>{"Allocate Dest. Volumes"}</ListSubheader>
            <Box p={2}>
                <Stepper activeStep={state.currentStep} orientation={isDesktop ? "vertical" : "horizontal"}>
                    <Step>
                        <StepLabel>{"Select Integration"}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{"Prepare Host"}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{"Allocate Volumes"}</StepLabel>
                    </Step>
                </Stepper>
            </Box>
        </Card>
    );
});

// ======================
// IntegrationSelectionStep
// ======================
interface IntegrationSelectionStepProps {}

const IntegrationSelectionStep: React.FC<IntegrationSelectionStepProps> = observer((p) => {
    const state = useGmAutoAllocationState();

    const integrationsList = useListProjectIntegrations();

    const redirectToIntegrationsPage = useRedirectToIntegrations();
    const goBackToMigrationWizard = useNavigateToMigrationWizardScreen();
    const redirectBackToAutoAllocation = useNavigateToAutoAllocation();
    const initIntegrationRedirectState = useIntegrationRedirectState((s) => s.initIntegrationRedirectState);

    const addNewIntegration = useCallback(() => {
        const integrationAlertConfig: IntegrationAlertConfig = {
            alertMessage: "You will be redirected back to the auto allocation wizard once an integration is added.",
            navigateBack: goBackToMigrationWizard,
            navigateBackLabel: "Back To Migration Wizard",
        };
        initIntegrationRedirectState({
            redirectFunc: redirectBackToAutoAllocation,
            alertConfig: integrationAlertConfig,
            preselectedHost: state.deploymentDetails?.getInfo().getDeployment().getSystemId(),
        });
        redirectToIntegrationsPage();
    }, [redirectBackToAutoAllocation, state, redirectToIntegrationsPage, goBackToMigrationWizard]);

    return (
        <QueryResultWrapper queryResult={integrationsList}>
            {integrationsList.data?.itemsList.length === 0 ? (
                <>
                    <Typography variant={"h4"}>{`Select Integration`}</Typography>
                    <Typography variant={"body1"}>No available integration found for allocation. Add one to get started.</Typography>
                    <br />
                    <Button variant={"outlined"} color={"primary"} onClick={addNewIntegration}>
                        Add Integration
                    </Button>
                </>
            ) : (
                <>
                    <Typography variant={"h4"}>{`Select Integration`}</Typography>
                    <Typography variant={"body1"}>{`Select an integration below to allocate migration destination volumes from`}</Typography>
                    <Typography variant={"body1"}>
                        Please <Link onClick={addNewIntegration}>click here</Link> if you would like to enable an integration not listed below.
                    </Typography>
                    <br />
                    <SelectIntegrationSection integrationsList={integrationsList.data?.itemsList} />
                </>
            )}
        </QueryResultWrapper>
    );
});

// ======================
// SelectIntegrationSection
// ======================

interface SelectIntegrationSectionProps {
    integrationsList: IntegrationConfigInfo.AsObject[];
}

export const SelectIntegrationSection: React.FC<SelectIntegrationSectionProps> = observer((p) => {
    const integrationDefs = useListIntegrationModules();

    return (
        <QueryResultWrapper queryResult={integrationDefs}>
            <Grid container spacing={2}>
                {p.integrationsList.map((i) => {
                    return (
                        <Grid item key={i.id}>
                            <IntegrationCard
                                module={getModuleConfigByModule(i.module, integrationDefs.data?.itemsList)}
                                instance={i}
                                cardProps={{ sx: { width: 325 } }}
                                mainActionButton={<SelectIntegrationButton integration={i} />}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </QueryResultWrapper>
    );
});

// ======================
// SelectIntegrationButton
// ======================

interface SelectIntegrationButtonProps {
    integration: IntegrationConfigInfo.AsObject;
}

export const SelectIntegrationButton: React.FC<SelectIntegrationButtonProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    const testProjectIntegration = useTestProjectIntegration();

    const handleSelect = useCallback(async () => {
        const statusId = uuid();
        state.setNewStatusId(statusId);
        const connected = await testProjectIntegration.mutateAsync({
            id: p.integration.id,
            deploymentId: state.systemID,
            statusId,
        });
        if (connected) {
            state.selectIntegration(p.integration);
            state.clearStatusId();
        }
    }, [state, p.integration]);

    return (
        <Button variant={"contained"} color={"primary"} onClick={handleSelect}>
            Select
        </Button>
    );
});

// ======================
// PrepareHostStep
// ======================
interface PrepareHostStepProps {}

const PrepareHostStep: React.FC<PrepareHostStepProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    const [firstResetDone, setFirstResetDone] = useState(false);

    useMountEffect(() => {
        state.hostReadiness.resetData();
        setFirstResetDone(true);
    });

    const integrationDefs = useListIntegrationModules();

    const integrationDef = getModuleConfigByModule(state.selectedIntegration?.module, integrationDefs.data?.itemsList);
    const supportAutoPrepare = integrationDef?.moduleDefinition.capabilitiesList
        .map((c) => c.capability)
        .includes(IntegrationDefinition.CapabilityDefinition.Capability.PREPARE);

    return (
        <QueryResultWrapper queryResult={integrationDefs}>
            <Typography variant={"h4"}>{`Prepare Host`}</Typography>
            {supportAutoPrepare && (
                <Typography color={"textSecondary"}>
                    {`Optionally, we can help you configure your host to connect to your destination storage if needed using our default best practices`}
                </Typography>
            )}
            {!supportAutoPrepare && (
                <Typography color={"textSecondary"}>{`Let's first validate whether your host meets all the prerequisites for auto allocation.`}</Typography>
            )}
            <br />
            {firstResetDone && (
                <>
                    {!state.hostReadiness.ready && <HostReadinessCheck />}
                    {state.hostReadiness.ready && state.hostReadiness.data.getReady() && <HostAlreadyReadyForAutoAlloc />}
                    {state.hostReadiness.ready && !state.hostReadiness.data.getReady() && <HostNotReadyForAutoAllocMsg />}
                    {state.hostReadiness.ready && !state.hostReadiness.data.getReady() && <HostConfigurationOptions supportAutoPrepare={supportAutoPrepare} />}
                </>
            )}
        </QueryResultWrapper>
    );
});

// ======================
// HostConfigurationOptions
// ======================
interface HostConfigurationOptionsProps {
    supportAutoPrepare: boolean;
}

const HostConfigurationOptions: React.FC<HostConfigurationOptionsProps> = observer((p) => {
    const state = useGmAutoAllocationState();

    const manualRetryOption = (
        <Box>
            <Typography
                variant={"body2"}
            >{`Please follow your vendor's instructions to make sure that this host has all necessary software dependencies, tools and configurations to access your destination storage. When your host is ready to provision new volumes, click the button below.`}</Typography>
            <br />
            <Button variant={"contained"} onClick={() => state.manualCheck()}>
                {"Check Again"}
            </Button>
        </Box>
    );

    const autoConfigureOptions = (
        <Grid container spacing={4}>
            <Grid item xs={12} md={6} xl={4}>
                <Card sx={{ height: "100%" }}>
                    <Box p={2} height={"100%"}>
                        <Box height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box flexGrow={1}>
                                <Typography align={"center"}>
                                    <SvgIcon style={{ fontSize: 100 }} viewBox={`0 0 100 100`}>
                                        <GiBigGear />
                                    </SvgIcon>
                                </Typography>
                                <Typography variant={"h5"} align={"center"}>
                                    {"Automatic Setup"}
                                </Typography>
                                <br />
                                <Typography>
                                    {
                                        "We will automatically configure your host according to the storage vendors' default deployment configuration. Additional packages may be installed on your host."
                                    }
                                </Typography>
                            </Box>
                            <br />
                            <Box>
                                <Typography align={"center"}>
                                    <Button variant={"contained"} color={"primary"} onClick={() => state.autoConfigure()}>
                                        {"Configure Host for me"}
                                    </Button>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
                <Card sx={{ height: "100%" }}>
                    <Box p={2} height={"100%"}>
                        <Box height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box flexGrow={1}>
                                <Typography align={"center"}>
                                    <SvgIcon style={{ fontSize: 100 }} viewBox={`0 0 100 100`}>
                                        <GoTools />
                                    </SvgIcon>
                                </Typography>
                                <Typography variant={"h5"} align={"center"}>
                                    {"Manual"}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography>
                                    {
                                        "You will configure your host on your own. Click below to continue when host connectivity to the storage is confirmed and host is in a state ready to provision volumes."
                                    }
                                </Typography>
                                <br />
                                <Typography align={"center"}>
                                    <Button variant={"contained"} onClick={() => state.manualCheck()}>
                                        {"Yes, host is already configured"}
                                    </Button>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    );
    return p.supportAutoPrepare ? autoConfigureOptions : manualRetryOption;
});

// ======================
// HostAlreadyReadyForAutoAlloc
// ======================
interface HostAlreadyReadyForAutoAllocProps {}

const HostAlreadyReadyForAutoAlloc: React.FC<HostAlreadyReadyForAutoAllocProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    return (
        <>
            <Alert color={"success"}>
                <AlertTitle>{"Great! Your host is ready for auto allocation"}</AlertTitle>
            </Alert>
            <br />
            <Button color={"primary"} variant={"contained"} onClick={() => state.selectCurrentStep(GmAutoAllocationStep.ALLOCATE_VOLUMES)}>
                {"Continue"}
            </Button>
        </>
    );
});

interface HostNotReadyForAutoAllocMsgProps {}

const HostNotReadyForAutoAllocMsg: React.FC<HostNotReadyForAutoAllocMsgProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    return (
        <>
            <Alert severity={"warning"}>
                {"Your host is not ready for auto allocation: "} {state.hostReadiness.data.getReason()}
            </Alert>
            <br />
        </>
    );
});

// ======================
// HostReadinessCheck
// ======================
interface HostReadinessCheckProps {}

const HostReadinessCheck: React.FC<HostReadinessCheckProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    useMountEffect(() => {
        state.hostReadiness.fetchData();
    });
    return (
        <>
            <AllocationProgressView noActions />
        </>
    );
});

// ======================
// AllocateVolumesStep
// ======================
interface AllocateVolumesStepProps {}

const AllocateVolumesStep: React.FC<AllocateVolumesStepProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    const backToMigrationWizard = useNavigateToMigrationWizardScreen(true);
    const { progressService, dialogService } = useAppServices();
    const allocateNow = async () => {
        state.setShowProgressDialog(true);
        const allocatedVolumes = await dialogService.catchAndAlertError(state.allocateVolumes());
        await state.waitForProgressDialogToClose();
        await progressService.track(state.applyAllocatedVolumesToWizard(allocatedVolumes));
        backToMigrationWizard();
    };

    return (
        <>
            <Typography variant={"h4"}>{`Allocate Destination Volumes`}</Typography>
            {getVendorAllocateVolumesStep(state, allocateNow)}
        </>
    );
});

const getVendorAllocateVolumesStep = (state: GmMigrationAutoAllocationState, allocateFunc: () => Promise<void>) => {
    const selectedIntegrationModule = state.selectedIntegration.module;
    if (selectedIntegrationModule === IntegrationModule.PURE) {
        return <PureCBSAllocateVolumesStep state={state} allocateFunc={allocateFunc} />;
    } else if (selectedIntegrationModule === IntegrationModule.AZURE) {
        return <AzureAllocateVolumesStep state={state} allocateFunc={allocateFunc} />;
    } else if (selectedIntegrationModule === IntegrationModule.DGS) {
        return <DGSAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.AWS) {
        return <AWSAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.NETAPP || selectedIntegrationModule === IntegrationModule.AWS_FSX_NETAPP) {
        return <NetappAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.FC_PURE) {
        return <PureCBSAllocateVolumesStep state={state} allocateFunc={allocateFunc} />;
    } else if (selectedIntegrationModule === IntegrationModule.FC_POWERSTORE || selectedIntegrationModule === IntegrationModule.POWERSTORE) {
        return <PowerStoreAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.FC_POWERMAX) {
        return <PowerMaxIntegration allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.GCP) {
        return <GcpAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.ORACLE) {
        return <OracleAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.SILKSDP) {
        return <SilkSdpAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.DIGITALOCEAN) {
        return <DigitalOceanAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.POWERFLEX) {
        return <PowerFlexAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.AZURESAN) {
        return <AzureElasticSanAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.VMWAREDATASTORE) {
        return <VmwareDatastoreAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.NUTANIX) {
        return <NutanixAllocateVolumesStep state={state} allocateFunc={allocateFunc} />;
    } else if (selectedIntegrationModule === IntegrationModule.OVIRT) {
        return <OvirtAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    }
};
